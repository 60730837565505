import React from 'react';
import PropTypes from 'prop-types';
import { nanoid } from 'nanoid';
import Layout from 'components/Layout';
import Meta from 'components/Meta';

// import { Usecases } from 'styles/usecases';
// import { Main } from 'styles';
// import BugTrack from 'styles/bug-tracking';
import TestimonialsSlider from 'components/TestimonialsSlider/TestimonialsSlider';
import FeatureContainer from '../components/FeatureContainer';

import { signUp } from '../utils/contentProvider/function';
import LoginWithGoogle from '../components/LoginWithGoogle';
import TrustedBy from '../components/TrustedBy';

import 'styles/usecases.scss';
import 'styles/bug-tracking.scss';
import NewFaq from '../components/NewFaq/NewFaq';

const websiteAnnotationFaq = [
  {
    q: 'What is a website annotation tool?',
    a: 'A website annotation tool is useful when you want to mark missing elements or incorrect information like text, image, video, or any other element/section. A website annotation like ruttl empowers you to add pixel-pinned contextual comments that can be shared & discussed with the team (comment threads), worked upon (edit mode) & resolved later - all inside ruttl.',
  },
  {
    q: 'How to annotate a website?',
    a: (
      <>
        <ol>
          <li>
            <span className="bold">Initiate Project:&nbsp;</span>
            Begin a feedback project for annotations.
          </li>
          <li>
            <span className="bold">Add URL:&nbsp;</span>
            Insert the website&apos;s URL.
          </li>
          <li>
            <span className="bold">Active Comment Mode:&nbsp;</span>
            Engage ruttl&apos;s annotation tools for intuitive marking.
          </li>
        </ol>
      </>
    ),
  },
  {
    q: 'Which types of websites can I annotate?',
    a: 'ruttl gives you the freedom to practically annotate on any website. Simply paste the URL inside your project & start annotating instantly!',
  },
  {
    q: 'Do I need any chrome extension to do this?',
    a: 'No, Chrome extension is not needed to annotate on websites. You can add a project, paste the URL of your website inside & start annotating with your team.',
  },
  {
    q: 'I work with clients. Do they need ruttl accounts to annotate?',
    a: 'No, your clients can provide contextual annotations without the need of having a ruttl account.',
  },
  {
    q: 'We have multiple web pages. Can ruttl handle it?',
    a: 'Yes, ruttl has the ability to fetch all pages added to your main domain, so you only have to enter the main URL once, select the pages from the next screen & add them to review inside ruttl.',
  },
  {
    q: 'Can we export the website with annotations as PDF?',
    a: (
      <>
        We currently don&apos;t have this functionality, but we&apos;ll be
        bringing that up soon. Stay tuned to our
        <a
          href="https://trello.com/b/D9T51EZX"
          target="_blank"
          rel="noopener noreferrer">
          &nbsp;Public Roadmap&nbsp;
        </a>
        for more information.
      </>
    ),
  },
  {
    q: 'Can I create a new version after an annotated web page is updated?',
    a: 'Yes, you can create a new version once the live web page is updated.',
  },
  {
    q: 'Do you integrate with whiteboard softwares like Miro?',
    a: (
      <>
        Not at the moment, but we&apos;ll be introducing Miro in our list of
        integrations soon. You can check out all our integrations
        <a
          href="https://ruttl.com/integrations/"
          target="_blank"
          rel="noopener noreferrer">
          &nbsp;integrations
        </a>
        here.
      </>
    ),
  },
  {
    q: 'What happens to the unresolved comments in my previous version? Can I copy them into my newer version?',
    a: 'Yes, when you add a newer version, you’ll get the option to add your unresolved comments from the previous version, and these comments will stick to their original position too - thereby eliminating any vagueness in your review process.',
  },
].map(({ q, a }) => ({
  q,
  a,
  uuid: nanoid(),
}));
const WebsiteAnnotationTool = ({ location }) => (
  <Layout location={location}>
    {({ toggleSignup }) => (
      <>
        <Meta
          title="Website Annotation Tool by Ruttl"
          description="Annotate web pages and websites easily with designers' and developers' best website annotation tool. Trusted by over 15,000 companies & professionals."
          url="https://ruttl.com/website-annotation-tool/"
        />
        <main className="styled-main">
          <section className="features-hero">
            <div className="container">
              <div className="index-hero__content">
                <h1
                  className="reveal-1"
                  style={{ maxWidth: 760, marginBottom: 25 }}>
                  Best Website Annotation Tool for Designers & Developers
                </h1>
                <p
                  className="index-hero--subhead"
                  style={{ marginTop: '40px' }}>
                  Annotate web pages easily by adding comments, editing CSS
                  elements and sharing precise design feedback.
                </p>

                <div className="hero-review-block">
                  <div className="review-stars">
                    <img src="/assets/img/stars.svg" alt="Star ratings" />
                    <p>Based on 100+ reviews on</p>
                  </div>

                  <div className="review-logos">
                    <img
                      src="/assets/img/logo/g2-crowd-full.png"
                      alt="G2 Crowd Logo"
                    />
                    <img
                      src="/assets/img/logo/capterra-full.png"
                      alt="Capterra Logo"
                    />
                    <img
                      src="/assets/img/logo/get-app-full.png"
                      alt="Get App Logo"
                    />
                  </div>
                </div>

                <div
                  className="reveal-1 button-container"
                  style={{ margin: '30px 0' }}>
                  <a href="https://web.ruttl.com" className="button">
                    Get started for free
                  </a>
                  <LoginWithGoogle
                    white
                    styleProp={{
                      padding: '5px 35px',
                    }}
                  />
                </div>
                <TrustedBy />
              </div>
            </div>
            <div className="styled-bug-tracking greyBg">
              <div className="container">
                <h2 className="center" id="view-demo">
                  How it works
                </h2>
                <div className="feature-banner">
                  <div
                    style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
                    <iframe
                      title="How To Annotate Websites With ruttl"
                      src="https://player.vimeo.com/video/734261366?h=4044e80b2f&autoplay=1&loop=1&title=0&byline=0&portrait=0&muted=1"
                      style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                      }}
                      frameBorder="0"
                      allow="autoplay; fullscreen; picture-in-picture"
                      allowFullScreen></iframe>
                  </div>
                  <script src="https://player.vimeo.com/api/player.js"></script>
                </div>
                <h2>Start Annotating Webpages in Just 10 Seconds</h2>
                <div className="flex-2 flex flex-wrap align-items-stretch">
                  <div className="wrapper">
                    <div className="card-single">
                      <div className="card-block">
                        <div className="card-icon">
                          <img
                            loading="lazy"
                            src="/assets/img/pot.svg"
                            alt="flower pot icon"
                            title="flower pot icon"
                          />
                        </div>
                        <div className="card-desc">
                          <h3>1. Create a project</h3>
                          <p>
                            Log into your ruttl account and create a new
                            project.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="wrapper">
                    <div className="card-single">
                      <div className="card-block">
                        <div className="card-icon">
                          <img
                            loading="lazy"
                            src="/assets/img/open-webpage.png"
                            alt="security icon"
                            title="security icon"
                          />
                        </div>
                        <div className="card-desc">
                          <h3>2. Add Any URL</h3>
                          <p>
                            Just copy and paste any website URL during the
                            project creation.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="wrapper">
                    <div className="card-single">
                      <div className="card-block">
                        <div className="card-icon">
                          <img
                            loading="lazy"
                            src="/assets/img/bug-tracking-maintainance.svg"
                            alt="spanner inside mechanical gear icon"
                            title="spanner inside mechanical gear icon"
                          />
                        </div>
                        <div className="card-desc">
                          <h3>3. Start Annotating</h3>
                          <p>
                            Click on any part of the website and start
                            annotating it with textual comments.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="center" style={{ padding: 20 }}>
              <a
                href="https://app.rutll.com.signup"
                className="button"
                type="button">
                Try ruttl for free
              </a>
            </div>
          </section>
          {/* <section className='container'>
            <div className="feature-banner">
              <div style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
                <iframe
                  title="How To Annotate Websites With ruttl"
                  src="https://player.vimeo.com/video/734261366?h=4044e80b2f&autoplay=1&loop=1&title=0&byline=0&portrait=0&muted=1"
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                  }}
                  frameBorder="0"
                  allow="autoplay; fullscreen; picture-in-picture"
                  allowFullScreen></iframe>
              </div>
              <script src="https://player.vimeo.com/api/player.js"></script>
            </div>
          </section> */}
        </main>
        <main className="usecases-styled-main">
          <div className="section features-main" id="features">
            <div className="container">
              <h2 className="center">Features our users love </h2>
              <FeatureContainer>
                <div className="feature-set-single">
                  <div className="flex flex-wrap align-items-center">
                    <div className="feature-img">
                      <img
                        src="/assets/img/leave-comments-website.jpg"
                        alt="illustration of leaving comment on live website"
                        title="illustration of leaving comment on live website"
                      />
                    </div>
                    <div
                      className="feature-txt"
                      style={{ transform: ' translateY(-50px)' }}>
                      <h2
                        className="feature-head"
                        style={{ maxWidth: '420px' }}>
                        Annotate web pages with comments
                      </h2>
                      <p>
                        Markup websites and leave detailed feedback with the
                        help of text comments and video recordings on live
                        webpages.
                      </p>
                      <button
                        type="button"
                        onClick={() => signUp(toggleSignup)}
                        className="button">
                        Get Started
                      </button>
                    </div>
                  </div>
                </div>
              </FeatureContainer>
              <FeatureContainer>
                <div className="feature-set-single">
                  <div className="flex flex-wrap align-items-center ">
                    <div className="feature-img">
                      <div className="feature-img-bg">
                        <img
                          src="/assets/img/tag-team-members.png"
                          alt="Tag your team members"
                          title="Tag your team members"
                        />
                      </div>
                    </div>
                    <div className="feature-txt">
                      <h2
                        className="feature-head"
                        style={{ maxWidth: '320px' }}>
                        Assign and resolve comments
                      </h2>
                      <p>
                        Tag your teammates, assign comments as tasks, set
                        deadlines, and resolve them once the task is finished
                      </p>
                      <button
                        type="button"
                        onClick={() => signUp(toggleSignup)}
                        className="button">
                        Get Started
                      </button>
                    </div>
                  </div>
                </div>
              </FeatureContainer>
              <FeatureContainer>
                <div className="feature-set-single">
                  <div className="flex flex-wrap align-items-center ">
                    <div className="feature-img">
                      <video
                        src="/assets/img/guest-commenting.mp4"
                        autoPlay
                        muted
                        loop
                        playsInline></video>
                    </div>
                    <div
                      className="feature-txt"
                      style={{ transform: ' translateY(-13px)' }}>
                      <h2
                        className="feature-head"
                        style={{ maxWidth: '360px' }}>
                        Enable comments by Guests and Clients
                      </h2>
                      <p style={{ maxWidth: '380px' }}>
                        No need to sign up or log in every time a client needs
                        to share feedback. Simply send a shareability link to
                        them and enable guest commenting
                      </p>
                      <button
                        type="button"
                        onClick={() => signUp(toggleSignup)}
                        className="button">
                        Get Started
                      </button>
                    </div>
                  </div>
                </div>
              </FeatureContainer>
              <FeatureContainer>
                <div className="feature-set-single">
                  <div className="flex flex-wrap align-items-center ">
                    <div className="feature-img">
                      <video
                        src="/assets/img/upload-image.mp4"
                        autoPlay
                        muted
                        loop
                        playsInline></video>
                    </div>
                    <div
                      className="feature-txt"
                      style={{ transform: ' translateY(-30px)' }}>
                      <h2 className="feature-head">
                        Replace images on live website
                      </h2>
                      <p>
                        Have a lot of selected images? See how each one of them
                        looks on your website and finalize the one that matches
                        the best
                      </p>
                      <button
                        type="button"
                        onClick={() => signUp(toggleSignup)}
                        className="button">
                        Get Started
                      </button>
                    </div>
                  </div>
                </div>
              </FeatureContainer>
              <FeatureContainer>
                <div className="feature-set-single">
                  <div className="flex flex-wrap align-items-center">
                    <div className="feature-img">
                      <img
                        src="/assets/img/record-version.png"
                        alt="illustration of leaving comment on live website"
                        title="illustration of leaving comment on live website"
                      />
                    </div>
                    <div
                      className="feature-txt"
                      style={{ transform: ' translateY(-30px)' }}>
                      <h2 className="feature-head">
                        Add and record multiple versions
                      </h2>
                      <p>
                        Create multiple versions of your website and navigate to
                        each version easily with a click and see all the
                        activities going on in there
                      </p>
                      <button
                        type="button"
                        onClick={() => signUp(toggleSignup)}
                        className="button">
                        Get Started
                      </button>
                    </div>
                  </div>
                </div>
              </FeatureContainer>
              <FeatureContainer>
                <div className="feature-set-single">
                  <div className="flex flex-wrap align-items-center ">
                    <div className="feature-img">
                      <img
                        loading="lazy"
                        src="/assets/img/integration-tools.png"
                        alt="Integrate with needed tools"
                        title="Integrate with needed tools"
                      />
                    </div>
                    <div
                      className="feature-txt"
                      style={{ transform: ' translateY(-20px)' }}>
                      <h2 className="feature-head">
                        Integrate with needed tools
                      </h2>
                      <p>
                        Export important comments to tools like Slack, Trello,
                        ClickUp, Jira and Asana so that you don&apos;t miss out
                        on any important updates
                      </p>
                      <button
                        type="button"
                        onClick={() => signUp(toggleSignup)}
                        className="button">
                        Get Started
                      </button>
                    </div>
                  </div>
                </div>
              </FeatureContainer>
            </div>
          </div>
          <TestimonialsSlider />

          <NewFaq
            // greyBg
            data={websiteAnnotationFaq}
          />
        </main>
      </>
    )}
  </Layout>
);

WebsiteAnnotationTool.propTypes = {
  location: PropTypes.object,
};

export default WebsiteAnnotationTool;
